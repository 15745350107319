export const Executives = [
  {
    title: "Tyler D'silva",
    img: "Tyler.webp",
    linkedin: "https://www.linkedin.com/in/tylerdsilva/",
    position: "Co-president",
    lastPosition: "",
  },
  {
    title: "Avni Kapoor",
    img: "Avni.webp",
    linkedin: "https://www.linkedin.com/in/avni-kapoor/",
    position: "Co-president",
    lastPosition: "",
  },
  {
    title: "Arthur Zhang",
    img: "Arthur.webp",
    linkedin: "https://www.linkedin.com/in/arthur0z/",
    position: "External Director",
    lastPosition: "",
  },
  {
    title: "Sylvia Zhang",
    img: "Sylvia.webp",
    linkedin: "https://www.linkedin.com/in/sylvia-zhang-siqi/",
    position: "VP Scoping",
    lastPosition: "",
  },
  {
    title: "Hinako Kamiya",
    img: "Hinako.webp",
    linkedin: "https://www.linkedin.com/in/hinako-kamiya/",
    position: "VP Communications",
    lastPosition: "",
  },
  {
    title: "Terry Kwok",
    img: "Terry.webp",
    linkedin: "https://www.linkedin.com/in/lai-chit-kwok-6074472b5/",
    position: "Internal Director",
    lastPosition: "",
  },
  {
    title: "Ronney Lok",
    img: "Ronney.webp",
    linkedin: "https://www.linkedin.com/in/ronneylok/",
    position: "Internal Director",
    lastPosition: "",
  },
  {
    title: "Harpreet Dubb",
    img: "Harpreet.webp",
    linkedin: "https://www.linkedin.com/in/harpreet-dubb/",
    position: "VP PM",
    lastPosition: "",
  },
  {
    title: "Fatma Omer ",
    img: "Fatma.webp",
    linkedin: "https://www.linkedin.com/in/fatmabinteomer/",
    position: "VP Talent",
    lastPosition: "",
  },
  {
    title: "Justin Yu ",
    img: "Justin.webp",
    linkedin: "https://www.linkedin.com/in/justin-yu-138450220/",
    position: "VP Design",
    lastPosition: "",
  },
];
