export const Members = [
  {
    title: "Caitlin Syho",
    img: "Caitlin.webp",
    linkedin: "https://www.linkedin.com/in/caitlinsyho/",
    lastPosition: "",
  },

  {
    title: "Kate Simone",
    img: "Kate.webp",
    linkedin: "https://www.linkedin.com/in/kate-simone-866335284/",
    lastPosition: "",
  },
  {
    title: "Eugene Zhu",
    img: "Eugene.webp",
    linkedin: "https://www.linkedin.com/in/eugenezhu/",
    lastPosition: "",
  },

  {
    title: "Duc Dang",
    img: "blueprint.webp",
    linkedin: "https://www.linkedin.com/in/ddang2001/",
    lastPosition: "",
  },
  {
    title: "Jeffrey Wong",
    img: "Jeffrey.webp",
    linkedin: "https://www.linkedin.com/in/wong-jeffrey/",
    lastPosition: "",
  },
  {
    title: "Tulika Varma",
    img: "Tulika.webp",
    linkedin: "",
    lastPosition: "",
  },
  {
    title: "Kousha Amouzesh",
    img: "Kousha.webp",
    linkedin: "https://www.linkedin.com/in/kousha-amouzesh/",
    lastPosition: "",
  },
  {
    title: "Iris Au-Yeung",
    img: "blueprint.webp",
    linkedin: "https://www.linkedin.com/in/irisau-yeung/",
    lastPosition: "",
  },
  {
    title: "Vinh Dang",
    img: "Quang.webp",
    linkedin: "https://www.linkedin.com/in/quang-vinh-dang-788ab0194/",
    lastPosition: "",
  },
  {
    title: "Ayana Hussain",
    img: "Ayana.webp",
    linkedin: "https://www.linkedin.com/in/ayana-hussain-a3399a227/",
    lastPosition: "",
  },
  {
    title: "Johnson Luong",
    img: "Johnson.webp",
    linkedin: "https://www.linkedin.com/in/johnson-luong/",
    lastPosition: "",
  },
  {
    title: "Derek Huang",
    img: "Derek.webp",
    linkedin: "https://www.linkedin.com/in/derekhuang1/",
    lastPosition: "",
  },
  {
    title: "Fabian Siswanto",
    img: "Fabian.webp",
    linkedin: "https://www.linkedin.com/in/fabiansiswanto/",
    lastPosition: "",
  },
  {
    title: "Mrinal Goshalia",
    img: "Mrinal.webp",
    linkedin: "https://www.linkedin.com/in/mrinal-goshalia/",
    lastPosition: "",
  },
  {
    title: "Teddy Malhan",
    img: "Teddy.webp",
    linkedin: "https://www.linkedin.com/in/teddymalhan/",
    lastPosition: "",
  },
  {
    title: "Lauryn Yau",
    img: "blueprint.webp",
    linkedin: "https://www.linkedin.com/in/laurynyau/",
    lastPosition: "",
  },
  {
    title: "Joshua li",
    img: "blueprint.webp",
    linkedin: "https://www.linkedin.com/in/joshuajli/",
    lastPosition: "",
  },
  {
    title: "Jerry Deng",
    img: "blueprint.webp",
    linkedin: "https://www.linkedin.com/in/jerrydngzh/",
    lastPosition: "",
  },
  {
    title: "Jimmy Hui",
    img: "Jimmy.webp",
    linkedin: "https://www.linkedin.com/in/jimmy--hui/",
    lastPosition: "",
  },
  {
    title: "Bryan Dang",
    img: "blueprint.webp",
    linkedin: "https://www.linkedin.com/in/bryanjhdang/",
    lastPosition: "",
  },
  {
    title: "Tayaba Abbasi",
    img: "Tayaba.webp",
    linkedin: "https://www.linkedin.com/in/tayaba-abbasi-8626a9194/",
    lastPosition: "",
  },
  {
    title: "Zariyan Mansoor",
    img: "Zariyan.webp",
    linkedin: "https://www.linkedin.com/in/zariyanmansoor/",
    lastPosition: "",
  },
  {
    title: "Sariha Uchida",
    img: "Sariha.webp",
    linkedin: "https://www.linkedin.com/in/sariha-uchida-0b6014266/",
    lastPosition: "",
  },
  {
    title: "Olivia Steed",
    img: "Olivia.webp",
    linkedin: "https://www.linkedin.com/in/olivia-steed-9426201b8/",
    lastPosition: "",
  },
];
